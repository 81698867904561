<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-layout row>
        <v-flex xs3>
          <v-select
            v-model="year"
            :items="years"
            prepend-inner-icon="event"
          />
        </v-flex>
      </v-layout>
    </v-toolbar>
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :expand="expand"
      item-key="month"
      class="elevation-1"
      disable-initial-sort
      hide-actions
    >
      <template #items="props">
        <tr
          :active="props.selected"
          @click="toggleSelection(props)"
        >
          <td class="text-xs-left">
            {{ props.item.month }}
          </td>
          <td class="text-xs-right">
            {{ props.item.reload }}
          </td>
          <td class="text-xs-right">
            {{ props.item.reward }}
          </td>
        </tr>
      </template>
      <template #expand="props">
        <v-layout column>
          <v-flex xs10>
            <v-card-text color="darken-5">
              <v-data-table
                :headers="detailHeaders"
                :items="props.item.detail"
                item-key="tier"
                class="elevation-1 px-3"
                disable-initial-sort
              >
                <template #items="thisProps">
                  <tr>
                    <td class="text-xs-left info--text">
                      {{ thisProps.item.time }}
                    </td>
                    <td class="text-xs-left info--text">
                      {{ thisProps.item.msisdn }}
                    </td>
                    <td class="text-xs-right info--text">
                      {{ thisProps.item.reload }}
                    </td>
                    <td class="text-xs-right info--text">
                      {{ thisProps.item.reward }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-flex>
        </v-layout>
      </template>
      <template #footer>
        <tr>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-right info">
            RM {{ totalReload }}
          </td>
          <td class="text-xs-right info">
            RM {{ totalReward }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'UGU Reloads'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2020, 4, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'UGU', disabled: false, to: '/ugu',
        },
        {
          text: title, disabled: true,
        },
      ],
      detailHeaders: [
        {
          text: 'Time',
          align: 'left',
          sortable: false,
          value: 'time',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: false,
          value: 'msisdn',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: false,
          value: 'reload',
        },
        {
          text: 'Reward (RM)',
          align: 'right',
          sortable: false,
          value: 'reward',
        },
      ],
      expand: false,
      items: [],
      loading: false,
      menu: false,
      selected: [],
      title,
      totalReward: 0.00,
      totalReload: 0.00,
      year: null,
      years: [],
    }
  },
  computed: {
    headers: function () {
      const header = [
        {
          text: 'Month',
          align: 'left',
          sortable: false,
          value: 'month',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: false,
          value: 'reload',
        },
        {
          text: 'Reward (RM)',
          align: 'right',
          sortable: false,
          value: 'reward',
        },
      ]

      return header
    },
  },
  watch: {
    year: function (val) {
      if (val) {
        this.getUguReward(val)
      }
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxYear = now.toFormat('yyyy')
    for (let year = maxYear; year >= 2020; year--) {
      this.years.push(year)
    }
    this.year = maxYear
  },
  methods: {
    getUguReloads: function (month) {
      const param = { year: this.year, month }
      const callback = (element) => element.month === month
      const index = this.items.findIndex(callback)
      this.items[index].detail.length < 1 && this.$rest.get('getUguReloads.php', createGetParams(param))
        .then(function (response) {
          this.items[index].detail = response.data
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          console.log(message)
        })
    },
    getUguReward: function (year) {
      const param = { year }
      this.loading = true
      this.$rest.get('getUguReward.php', createGetParams(param))
        .then(function (response) {
          this.items = response.data.item
          this.totalReload = response.data.totalReload
          this.totalReward = response.data.totalReward
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
    toggleSelection: function (item) {
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
      if (item.expanded) {
        this.getUguReloads(item.item.month)
      }
    },
  },
}
</script>
